import Typography from "@components/Typography";
import { Tooltip } from "@nextui-org/react";
import { ContentPrice, ExpandedContent } from "@queries/creator/getContentList";
import { getCurrencyFormat } from "@utils/getCurrency";
import { getItem, setItem } from "@utils/localStorage";
import React, { useEffect, useMemo, useState } from "react";

const Price: React.FC<{
  prices?: (ContentPrice | undefined)[];
  defaultCurrency: string;
}> = ({ prices, defaultCurrency }) => {
  const [currentCurrencyIndex, setCurrency] = useState(0);

  const currencies = useMemo(
    () => [
      ...(prices ?? [])?.reduce((acc, price) => {
        if (price) {
          acc.add(price.currency);
        }

        return acc;
      }, new Set() as Set<string>),
    ],
    [prices],
  );
  let currentIndex = prices?.findIndex(
    (price) =>
      price?.currency === currencies.at(currentCurrencyIndex) &&
      price?.content_access_type === "PURCHASE",
  );

  if (currentIndex === -1 || !currentIndex) {
    currentIndex = 0;
  }

  const current = prices?.at(currentIndex)!;

  const renderPrice = (price: ExpandedContent["share"]["prices"][number]) =>
    (price &&
      price.amount &&
      getCurrencyFormat(price.currency).format(price.amount / 100)) ??
    "-";

  const toggleCurrency = () => {
    setCurrency((currentCurrencyIndex + 1) % currencies.length);
    setItem(
      "preferred-currency",
      currencies.at(currentCurrencyIndex + 1) ?? "USD",
    );
  };

  useEffect(() => {
    if (currencies) {
      setCurrency(
        currencies.findIndex((currency) => defaultCurrency === currency),
      );
    }
  }, [currencies, defaultCurrency]);

  if (!prices || prices.length === 0)
    return (
      <Typography
        tracking="tighter"
        className="text-[rgb(var(--media-gray-800))]"
        type="body"
        size="md"
      >
        n/a
      </Typography>
    );

  return (
    <Tooltip
      placement="bottom-start"
      content={
        <div className="flex flex-col justify-between gap-2">
          {prices.map(
            (price, index) =>
              index !== currentIndex &&
              price && (
                <div key={price.content_access_type + price.currency}>
                  <span>{price.content_access_type}:</span>{" "}
                  <small className="float-end ps-2">{renderPrice(price)}</small>
                </div>
              ),
          )}
        </div>
      }
    >
      <div className="group">
        <Typography
          tracking="tighter"
          className="text-[rgb(var(--media-gray-800))]"
          type="body"
          size="md"
          onClick={toggleCurrency}
        >
          {renderPrice(current)}

          <span className="arrow-down ms-1 inline-block align-middle transition-all [--arrow-color:currentColor] [--arrow-size:6px] group-hover:rotate-180" />
        </Typography>
      </div>
    </Tooltip>
  );
};

export default Price;
