import SvgIcon, { SvgIconProps } from "./SvgIcon";

const Copy: React.FC<SvgIconProps> = ({ color = "#1F2A37", ...props }) => {
  return (
    <SvgIcon width={20} height={20} color="none" viewBox="0 0 20 20" {...props}>
      <path
        d="M6.66675 6.66669V4.33335C6.66675 3.39993 6.66675 2.93322 6.8484 2.5767C7.00819 2.2631 7.26316 2.00813 7.57676 1.84834C7.93328 1.66669 8.39999 1.66669 9.33341 1.66669H15.6667C16.6002 1.66669 17.0669 1.66669 17.4234 1.84834C17.737 2.00813 17.992 2.2631 18.1518 2.5767C18.3334 2.93322 18.3334 3.39993 18.3334 4.33335V10.6667C18.3334 11.6001 18.3334 12.0668 18.1518 12.4233C17.992 12.7369 17.737 12.9919 17.4234 13.1517C17.0669 13.3334 16.6002 13.3334 15.6667 13.3334H13.3334M4.33341 18.3334H10.6667C11.6002 18.3334 12.0669 18.3334 12.4234 18.1517C12.737 17.9919 12.992 17.7369 13.1518 17.4233C13.3334 17.0668 13.3334 16.6001 13.3334 15.6667V9.33335C13.3334 8.39993 13.3334 7.93322 13.1518 7.5767C12.992 7.2631 12.737 7.00813 12.4234 6.84834C12.0669 6.66669 11.6002 6.66669 10.6667 6.66669H4.33341C3.39999 6.66669 2.93328 6.66669 2.57676 6.84834C2.26316 7.00813 2.00819 7.2631 1.8484 7.5767C1.66675 7.93322 1.66675 8.39993 1.66675 9.33335V15.6667C1.66675 16.6001 1.66675 17.0668 1.8484 17.4233C2.00819 17.7369 2.26316 17.9919 2.57676 18.1517C2.93328 18.3334 3.39999 18.3334 4.33341 18.3334Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default Copy;
