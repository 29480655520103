import Typography, { TypographyProps } from "@components/Typography";
import { ContentSplit } from "@queries/creator/getContentList";
import React from "react";

const formatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

function SplitShare<Component extends React.ElementType>({
  shares,
  ...rest
}: Partial<TypographyProps<Component>> & {
  shares?: ContentSplit;
}) {
  return (
    // @ts-expect-error
    <Typography
      tracking="tighter"
      className="text-[rgb(var(--media-gray-800))]"
      type="body"
      size="md"
      {...rest}
    >
      {shares
        ? formatter.format(Number(shares.value) / Number(shares.base))
        : "n/a"}
    </Typography>
  );
}

export default SplitShare;
