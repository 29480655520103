enum Roles {
  "creator.admin" = "creator.admin",
  "md.admin" = "md.admin",
}

export enum RolesToLabel {
  "creator.admin" = "Creator",
  "md.admin" = "Partner",
}

export default Roles;
