import { MyButton } from "@components/Button";
import Typography from "@components/Typography";
import ArrowUpRight from "@icons/ArrowUpRight";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Image,
  Input,
} from "@nextui-org/react";
import {
  ContentStatus,
  ExpandedContent,
  SplitType,
} from "@queries/creator/getContentList";
import NextImage from "next/image";
import Price from "@components/ContentCard/Price";
import { getItem, setItem } from "@utils/localStorage";
import SplitShare from "@components/ContentCard/SplitShare";
import StatusBadge from "@components/ContentCard/StatusBadge";
import Roles from "@enums/Roles";
import Copy from "@icons/Copy";
import copy from "@utils/copy";
import { toast } from "sonner";
import { getCurrencyFormat } from "@utils/getCurrency";
import { fromCents } from "@utils/fromCents";
import NoImage from "/public/static/no-image.webp";
import RoleSpecific from "@components/RoleSpecific";
import Link from "next/link";
import { useRouter } from "next/navigation";

const ContentCard: React.FC<{
  card: ExpandedContent;
  onInvite: (content_id: string) => void;
}> = ({ card, onInvite }) => {
  const router = useRouter();

  const handleCopy = () => {
    if (typeof window === "undefined") return;

    copy(card.share?.share_url ?? "no url");

    toast.success("Url copied to clip board!", { id: "share_url_copy" });
  };

  const handleResumeCardProgress = () => {
    setItem("content_id", card.content_id);
    router.push("/create-content", { scroll: true });
  };

  return (
    <Card
      shadow="none"
      className="border-1 border-[rgb(var(--media-gray-200))] bg-[rgb(var(--media-gray-50))] sm:max-w-[400px] lg:max-w-none"
      classNames={{
        header: "p-4 bg-background-olyn",
      }}
    >
      <CardHeader className="flex-wrap items-end justify-between gap-4">
        <div className="flex flex-row gap-8">
          <div>
            <Typography
              className="mb-1 leading-none tracking-[-0.03em] text-[rgb(var(--media-gray-500))]"
              type="body"
              size="xs"
            >
              Total earned
            </Typography>
            <Typography
              className="tracking-[-0.03em] text-[rgb(var(--media-gray-800))]"
              type="body"
              size="md"
            >
              {(card.analytics?.total_earned &&
                getCurrencyFormat(card.analytics?.total_earned.currency).format(
                  fromCents(card.analytics?.total_earned.amount),
                )) ??
                "-"}
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1 leading-none tracking-[-0.03em] text-[rgb(var(--media-gray-500))]"
              type="body"
              size="xs"
            >
              Total views
            </Typography>
            <Typography
              className="tracking-[-0.03em] text-[rgb(var(--media-gray-800))]"
              type="body"
              size="md"
            >
              {card.analytics?.total_purchases ?? "-"}
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1 leading-none tracking-[-0.03em] text-[rgb(var(--media-gray-500))]"
              type="body"
              size="xs"
            >
              Active partners
            </Typography>
            <Typography
              className="tracking-[-0.03em] text-[rgb(var(--media-gray-800))]"
              type="body"
              size="md"
            >
              {card.analytics?.total_partners ?? "-"}
            </Typography>
          </div>
        </div>
        <MyButton
          className="shrink-o"
          color="secondary"
          size="sm"
          as={Link}
          isDisabled={card.content_status !== ContentStatus.PUBLISHED}
          href={`/analytics?content_id=${card.content_id}`}
          endContent={<ArrowUpRight color="currentColor" />}
        >
          Analytics
        </MyButton>
      </CardHeader>
      <Divider />
      <div className="relative aspect-video w-full">
        <StatusBadge
          classNames={{ base: "absolute left-4 top-4 z-10" }}
          status={card.content_status}
        />
        {card.content_image ? (
          <Image
            as={NextImage}
            src={card.content_image?.value}
            fill
            alt={card.title}
            radius="none"
            classNames={{ wrapper: "z-0 w-full h-full !max-w-none" }}
          />
        ) : (
          <NextImage
            src={NoImage}
            fill
            className="object-cover opacity-20"
            alt={card.title}
          />
        )}
        <div className="z-1 absolute bottom-0 w-full bg-content-card px-4 pb-3">
          <h2 className="text-[2rem] font-medium not-italic leading-none tracking-tight text-[rgb(var(--media-gray-800))]">
            {card.title}
          </h2>
        </div>
      </div>
      <Divider />
      <CardBody>
        <div className="flex flex-row gap-8">
          <div>
            <Typography
              tracking="tighter"
              className="mb-1 leading-none text-[rgb(var(--media-gray-500))]"
              type="body"
              size="xs"
            >
              Price
            </Typography>
            <Price
              prices={card.share?.prices}
              defaultCurrency={getItem("preferred-currency") ?? "USD"}
            />
          </div>
          <div>
            <Typography
              tracking="tighter"
              className="mb-1 leading-none text-[rgb(var(--media-gray-500))]"
              type="body"
              size="xs"
            >
              Split Partners
            </Typography>
            <SplitShare
              shares={card.share?.splits?.find(
                (split) => split.split_type === SplitType.PARTNER,
              )}
            />
          </div>
          <div>
            <Typography
              tracking="tighter"
              className="mb-1 leading-none text-[rgb(var(--media-gray-500))]"
              type="body"
              size="xs"
            >
              Split Referrals
            </Typography>
            <SplitShare
              shares={card.share?.splits?.find(
                (split) => split.split_type === SplitType.REFERRAL,
              )}
            />
          </div>
          <div>
            <Typography
              tracking="tighter"
              className="mb-1 leading-none text-[rgb(var(--media-gray-500))]"
              type="body"
              size="xs"
            >
              Non-Profit
            </Typography>
            <SplitShare
              shares={card.share?.splits?.find(
                (split) => split.split_type === SplitType.ADVOCATE,
              )}
            />
          </div>
        </div>
      </CardBody>
      <Divider />
      <CardFooter className="flex-col items-start gap-4 pb-9 pl-6 pr-4 pt-8">
        {card.content_status === ContentStatus.DRAFT && (
          <RoleSpecific acceptOnly={[Roles["creator.admin"]]}>
            <MyButton
              fullWidth
              color="primary"
              onPress={handleResumeCardProgress}
            >
              Resume
            </MyButton>
          </RoleSpecific>
        )}
        {card.content_status !== ContentStatus.DRAFT && (
          <>
            <Typography type="body" size="sm" className="px-2 text-left">
              Your Film Link to Share
            </Typography>

            <div className="flex w-full gap-4">
              <Input
                readOnly
                isDisabled={card.content_status !== ContentStatus.PUBLISHED}
                defaultValue={
                  card.content_status === ContentStatus.PUBLISHED
                    ? card.share?.share_url!
                    : "Content not yet published."
                }
                variant="bordered"
                onClick={handleCopy}
              />
              <MyButton
                color="secondary"
                isIconOnly
                isDisabled={card.content_status !== ContentStatus.PUBLISHED}
                startContent={<Copy color="currentColor" />}
                onPress={handleCopy}
              />
            </div>

            <RoleSpecific acceptOnly={[Roles["creator.admin"]]}>
              <Typography type="body" size="sm" className="px-2 text-left">
                Invite Partners to Share your content
              </Typography>

              <MyButton
                fullWidth
                color="primary"
                isDisabled={card.content_status !== ContentStatus.PUBLISHED}
                onPress={() => onInvite(card.content_id)}
              >
                + Invite Partners
              </MyButton>
            </RoleSpecific>
          </>
        )}
      </CardFooter>
    </Card>
  );
};

export default ContentCard;
