import { MyButton } from "@components/Button";
import Search from "@icons/Search";
import { Input } from "@nextui-org/react";
import { Filters } from "@queries/creator/getContentList";
import { AnimatePresence, motion } from "framer-motion";
import throttle from "lodash.throttle";
import React, { useCallback, useState } from "react";

const FilterPanel: React.FC<{
  onChange: (value: { query: string }) => void;
  values: Filters;
}> = ({ onChange, values }) => {
  const [searchVisible, setSearchVisibility] = useState(false);

  const handleChange = useCallback(
    () =>
      throttle((value: string) => {
        onChange({ query: value });
      }, 1000),
    [onChange],
  );

  const toggleSearch = () => {
    setSearchVisibility(!searchVisible);

    if (searchVisible) {
      handleChange()("");
    }
  };

  return (
    <div>
      <div className="flex gap-4">
        <AnimatePresence>
          {searchVisible && (
            <motion.div
              initial={{ width: 0, opacity: 0 }}
              animate={{ width: 150, opacity: 1 }}
              exit={{ width: 0, opacity: 0 }}
            >
              <Input
                autoFocus
                radius="sm"
                variant="bordered"
                defaultValue={values.query}
                onValueChange={handleChange}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <MyButton
          color="secondary"
          size="lg"
          isIconOnly
          startContent={<Search size={20} color="currentColor" />}
          onPress={toggleSearch}
        ></MyButton>
      </div>
    </div>
  );
};

export default FilterPanel;
