import {
  UndefinedInitialDataOptions,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import { client } from "@utils/axios-utils";

export interface UserData {
  requested_on: "2024-08-23T15:16:20";
  data: {
    last_name: string;
    first_name: string;
    email: string;
  };
}

export const getUser = async () => {
  const response = await client().get<UserData>("v2/creators/me");

  return response.data;
};

export const GetUserKey = ["creator", "me"];

export const getUserProfileOptions = (
  options?: Partial<
    Omit<
      UndefinedInitialDataOptions<UserData, unknown, UserData>,
      "queryKey" | "queryFn" | "select"
    >
  >,
) =>
  queryOptions({
    queryKey: GetUserKey,
    queryFn: getUser,
    select: (data) => {
      return data.data;
    },
    retry: false,
    gcTime: Infinity,
    ...options,
  });

export const useGetProfile = (
  options?: Partial<
    Omit<
      UndefinedInitialDataOptions<UserData, unknown, UserData>,
      "queryKey" | "queryFn" | "select"
    >
  >,
) => useQuery({ ...getUserProfileOptions(options), staleTime: Infinity });
