import {
  GetContentRequest,
  GetContentResponse,
} from "@queries/creator/getContentList";
import {
  InfiniteData,
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { client } from "@utils/axios-utils";
import { AxiosError } from "axios";

export const getContentList = async ({
  page,
  page_size,
  expand_ids = ["share_id", "analytics_id"],
  query,
}: GetContentRequest) => {
  const response = await client().get<GetContentResponse>(
    "v2/master_distributors/contents",
    {
      params: {
        page,
        page_size,
        expand_ids: "share_id,analytics_id,content_image_id",
        search: query,
      },
    },
  );

  return response.data;
};

export const useGetContentList = (
  query: GetContentRequest,
  options?: Partial<
    UseQueryOptions<
      GetContentResponse,
      AxiosError<DefaultErrorResponse>,
      GetContentResponse
    >
  >,
) =>
  useQuery({
    queryFn: () => getContentList(query),
    queryKey: ["get", "content", query],
    placeholderData: keepPreviousData,
    staleTime: 10 * 60 * 1000,
    gcTime: 60 * 60 * 1000,
    ...options,
  });

export const useGetContentInfiniteList = (
  query: any,
  options?: Partial<
    Omit<
      UseInfiniteQueryOptions<
        GetContentResponse,
        AxiosError<DefaultErrorResponse>,
        InfiniteData<GetContentResponse>
      >,
      "queryFn"
    >
  >,
) =>
  useInfiniteQuery({
    queryKey: ["get", "content", "infinite", query],
    placeholderData: keepPreviousData,
    queryFn: ({ pageParam = 0, ...rest }, ...props) =>
      getContentList({ page: pageParam, page_size: 10, ...query }),
    initialPageParam: 1,
    getPreviousPageParam: (firstPage, pages) => {
      if (firstPage.page === 1) return undefined;

      return firstPage.page - 1;
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.total_count <= lastPage.page * lastPage.page_size)
        return undefined;

      return lastPage.page + 1;
    },
    staleTime: 10 * 60 * 1000,
    gcTime: 60 * 60 * 1000,
    ...options,
  });
