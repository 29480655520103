import SvgIcon, { SvgIconProps } from "./SvgIcon";

const ArrowUpRight: React.FC<SvgIconProps> = ({
  color = "#1F2A37",
  ...props
}) => {
  return (
    <SvgIcon width={16} height={16} color="none" viewBox="0 0 16 16" {...props}>
      <path
        d="M4.66675 11.3333L11.3334 4.66663M11.3334 4.66663H4.66675M11.3334 4.66663V11.3333"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ArrowUpRight;
