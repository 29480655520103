import { useAuth } from "@context/Auth";
import Roles from "@enums/Roles";
import React from "react";

const RoleSpecific: React.FC<{
  children: React.ReactNode;
  acceptOnly?: Roles[];
  rejectOnly?: Roles[];
}> = ({ children, rejectOnly, acceptOnly }) => {
  const { role } = useAuth();

  if (acceptOnly && rejectOnly) {
    throw new Error(
      "you can only use one of the property at a time: acceptOnly or rejectOnly, but not both",
    );
  }

  const accept = acceptOnly ? acceptOnly : [];

  if (!role) {
    if (accept.length === 0) {
      return children;
    }

    return;
  }

  if (accept.includes(role)) {
    return children;
  }
};

export default RoleSpecific;
