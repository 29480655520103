import Typography from "@components/Typography";
import { Card, CardBody, cn, Spinner } from "@nextui-org/react";
import React from "react";

interface ContentLoadingProps {
  type: "loading";
  data?: unknown;
}

interface ContentErrorProps {
  type: "error";
  data?: Error;
}

interface ContentNoItemsProps {
  type: "empty";
  data?: React.ReactNode;
}

const ContentStatusCard: React.FC<
  ContentErrorProps | ContentLoadingProps | ContentNoItemsProps
> = ({ type, data }) => {
  return (
    <Card
      shadow="none"
      className="min-h-96 w-full border-1 border-[rgb(var(--media-gray-200))] bg-[rgb(var(--media-gray-50))] sm:max-w-[400px] lg:max-w-none"
      classNames={{
        header: "p-4 bg-background-olyn",
      }}
    >
      <CardBody
        className={cn("items-center justify-center", {
          "opacity-10": type === "loading",
        })}
      >
        {type === "empty" && (
          <Typography type="body">{data || "No content"}</Typography>
        )}
        {type === "loading" && <Spinner color="current" size="lg" />}
        {type === "error" && (
          <Typography type="body" size="sm" className="text-warning">
            {data?.message}
          </Typography>
        )}
      </CardBody>
    </Card>
  );
};

export default ContentStatusCard;
