import {
  Chip,
  ChipProps,
  extendVariants,
  VariantProps,
} from "@nextui-org/react";
import { ContentStatus } from "@queries/creator/getContentList";
import React from "react";

const StatusToLabel = {
  [ContentStatus.PUBLISHED]: "Live",
  [ContentStatus.DISABLED]: "Disabled",
  [ContentStatus.DRAFT]: "Draft",
  [ContentStatus.IN_REVIEW]: "Review",
};

const CustomChip = extendVariants(Chip, {
  variants: {
    color: {
      [ContentStatus.PUBLISHED as string]:
        "bg-[rgb(var(--button-color-primary-default-background))] text-[rgb(var(--button-color-primary-default-text))] shadow-button-main",
      [ContentStatus.DISABLED as string]:
        "border border-[rgb(var(--button-color-secondary-default-border))] bg-[rgb(var(--button-color-secondary-default-background))] text-[rgb(var(--button-color-secondary-default-text))] shadow-button-main",
      [ContentStatus.DRAFT as string]:
        "border border-[rgb(var(--button-color-secondary-default-border))] bg-[rgb(var(--button-color-secondary-default-background))] text-[rgb(var(--button-color-secondary-default-text))] shadow-button-main",
      [ContentStatus.IN_REVIEW as string]:
        "border border-[rgb(var(--button-color-secondary-default-border))] bg-[rgb(var(--button-color-secondary-default-background))] text-[rgb(var(--button-color-secondary-default-text))] shadow-button-main",
    },
  },
});

interface StatusBadgeProps extends VariantProps<typeof CustomChip> {
  status: ContentStatus;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, ...rest }) => {
  return (
    <CustomChip {...rest} color={status}>
      {StatusToLabel[status]}
    </CustomChip>
  );
};

export default StatusBadge;
