export const fallbackCopyToClipboard = (text: string): void => {
  const textArea = document.createElement("textarea");

  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }

  document.body.removeChild(textArea);
};

export const copy = (text: string) => {
  // @ts-expect-error chrome is not in globalThis
  if (!!window.chrome) {
    navigator.permissions
      // @ts-expect-error  Type '"clipboard-write"' is not assignable to type 'PermissionName'.
      .query({ name: "clipboard-write" })
      .then((result) => {
        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard.writeText(text);

          return;
        }

        console.log("Permissions not granted to write into clipboard", result);
      })
      .catch((e) => {
        console.log(
          "Error during checking permissions to write into clipboard",
          e,
        );
      });

    return;
  }

  try {
    navigator.clipboard.writeText(text);
  } catch (e) {
    console.log("Error write to clipboard", e);
    fallbackCopyToClipboard(text);
  }
};

export default copy;
