import { cn } from "@nextui-org/react";
import { twMerge } from "tailwind-merge";

export interface SvgIconProps {
  /** width, should always be the same as height */
  size?: number;
  /** width, should always be the same as height */
  width?: number;
  /** height, should always be the same as width */
  height?: number;
  /** supports any value: named, hex, hsl or currentColor */
  color?: string;
  /** is svg prop that defines the position and dimension, in user space, of an SVG viewport. */
  viewBox?: string;
  className?: string;
  iconRef?: React.RefObject<SVGSVGElement>;
  style?: any;
}

const SvgIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = ({
  children,
  size,
  width,
  height,
  color,
  className,
  iconRef,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      width={size || width}
      height={size || height}
      fill={color}
      className={twMerge(cn("inline-block", className))}
      {...props}
    >
      {children}
    </svg>
  );
};

export default SvgIcon;
