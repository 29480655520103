import {
  UndefinedInitialDataOptions,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import { client } from "@utils/axios-utils";

export interface CreatorOnboardData {
  requested_on: "2024-08-23T15:16:20";
  data: {
    creator_last_name: string;
    creator_first_name: string;
    creator_phone_number: string | undefined;
    creator_email: string;
    data: Record<string, string> | null;
    verification: Record<string, string> | null;
  };
}

export const getCreatorOnbaordingStatus = async () => {
  const response = await client().get<CreatorOnboardData>(
    "v2/creators/onboardings",
  );

  return response.data;
};

export const GetCreatorOnboardingKey = ["GET_CREATOR_ONBOARDING_STATUS"];

export const getCreatorOnboardingStatusOptions = (
  options?: Partial<
    Omit<
      UndefinedInitialDataOptions<
        CreatorOnboardData,
        unknown,
        CreatorOnboardData
      >,
      "queryKey" | "queryFn" | "select"
    >
  >,
) =>
  queryOptions({
    queryKey: GetCreatorOnboardingKey,
    queryFn: getCreatorOnbaordingStatus,
    select: (data) => {
      return data.data;
    },
    retry: false,
    gcTime: Infinity,
    ...options,
  });

export const GetCreatorOnboardingStatus = (
  options?: Partial<
    Omit<
      UndefinedInitialDataOptions<
        CreatorOnboardData,
        unknown,
        CreatorOnboardData
      >,
      "queryKey" | "queryFn" | "select"
    >
  >,
) =>
  useQuery({
    ...getCreatorOnboardingStatusOptions(options),
    staleTime: Infinity,
  });
